import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./Courses.module.css";

import * as Constants from "../../lib/constants";

import Layout from "../../components/Layout";

const Courses = ({ path, coursesPageSlugMap, ownLanguageKeys, footerItems, pagePath }) => {
  const [langUser, setLangUser] = useState(Constants.ALL_LANGUAGES.enUS);
  const coursesByLangIndexObj = coursesPageSlugMap[langUser];
  const coursesByLangIndexPages = Object.keys(coursesByLangIndexObj).map((langToLearn) => ({
    langToLearn,
    ...coursesByLangIndexObj[langToLearn],
  }));

  const toReadableLanguage = (lanKey) => {
    return ownLanguageKeys[lanKey];
  };

  const langUserOptions = Object.keys(coursesPageSlugMap);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramLangUser = queryParams.get("l");
    if (langUserOptions.includes(paramLangUser)) {
      setLangUser(paramLangUser);
    }
  }, []);

  const selectedFlagPath = `/flags/${Constants.FLAG_KEYS[langUser]}.svg`;

  return (
    <Layout
      contentContainerStyle={{ maxWidth: "100%", padding: 0 }}
      title="Select your language"
      metaDesc={"Choose the language you want to learn to explore the word lists by topics"}
      ogUrlPath={pagePath}
      canonicalPath={pagePath}
    >
      <main style={{ paddingTop: 20 }}>
        <div style={{ maxWidth: 1110, margin: "0 auto", padding: "0 10px" }}>
          <h1 style={{ marginTop: 0, fontSize: 28 }}>
            I speak {Constants.LANGUAGE_NAME_KEYS_ENGLISH[langUser]}
          </h1>
          <div>
            <select
              onChange={(e) => {
                const newLangUser = e.target.value;
                setLangUser(e.target.value);
                const queryParams = new URLSearchParams(window.location.search);
                queryParams.set("l", newLangUser);
                window.history.replaceState(null, null, "?" + queryParams.toString());
              }}
              value={langUser}
              className={styles.customSelect}
            >
              {langUserOptions.map((langKey) => (
                <option key={langKey} value={langKey}>
                  {toReadableLanguage(langKey)}
                </option>
              ))}
            </select>
            <div
              style={{
                width: 200,
                position: "relative",
                top: -50,
                backgroundColor: Constants.LANGUAGE_BG_COLORS[langUser],
                pointerEvents: "none",
              }}
              className={styles.languageButton}
            >
              <img
                src={selectedFlagPath}
                alt={ownLanguageKeys[langUser]}
                className={styles.languageFlag}
              />
              <h2 className={styles.languageLabel}>{toReadableLanguage(langUser)}</h2>
              <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
                <StaticImage
                  src="../../images/arrow-down.svg"
                  alt="arrow down"
                  height={16}
                  width={16}
                />
              </div>
            </div>
          </div>

          <h2 style={{ marginTop: 0, fontSize: 28 }}>I want to learn</h2>
          <div style={{ display: "flex", flexWrap: "wrap", maxWidth: 900 }}>
            {coursesByLangIndexPages.map((item) => {
              const { slug, label } = item;
              const flagKey = Constants.FLAG_KEYS[item.langToLearn];
              return (
                <div
                  key={item.langToLearn}
                  style={{
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <Link
                    to={`/${slug}`}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          backgroundColor: Constants.LANGUAGE_BG_COLORS[item.langToLearn],
                          minWidth: 250,
                        }}
                        className={styles.languageButton}
                      >
                        <img
                          src={`/flags/${flagKey}.svg`}
                          alt={label}
                          className={styles.languageFlag}
                        />
                        <h2 className={styles.languageLabel}>{label}</h2>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <footer style={{ backgroundColor: "#09142efc", marginTop: 50, padding: "60px 20px" }}>
          <div style={{ display: "flex", flexWrap: "wrap", maxWidth: 1110, margin: "0 auto" }}>
            {footerItems.map((footerItem) => {
              return (
                <div key={footerItem.ownLanguageLabel} style={{ width: 250 }}>
                  <h3 style={{ color: "#fff" }}>{footerItem.ownLanguageLabel}</h3>
                  <div>
                    {footerItem.langToLearnLinks.map((langToLearnLink) => (
                      <div key={langToLearnLink.slug}>
                        <Link
                          to={`/${langToLearnLink.slug}`}
                          style={{ color: "#fff", textDecoration: "none" }}
                        >
                          {langToLearnLink.label}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </footer>
      </main>
    </Layout>
  );
};

export default Courses;
